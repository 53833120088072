import { makeStyles } from "@mui/styles";
import React, { PropsWithChildren, useState } from "react";
import classNames from "classnames";
import { MapCardPinPopup } from "../MapCardPinPopup";
import { ClickAwayListener, Hidden, Tooltip } from "@mui/material";
import { FHNextImage, ImageLoader } from "../FHNextImage";
import { LocationMapOverrideProps } from "src/pages/mapped-templates/LocationMapTemplate";
import FHButton from "../FHButton";
import classnames from "classnames";
import { debounce } from "src/utils";
import { isIOS } from "@components/Utils";

const useStyles = (
  mapPin: string,
  mapPinHover: string,
  mapPinSelected: string,
) =>
  makeStyles((theme) => {
    return {
      root: {
        width: "100%",
        paddingTop: 20,
        paddingBottom: 20,
        backgroundColor: theme.palette.grey[50],
      },
      isCompareLocations: {
        paddingTop: 30,
      },
      mapCard: {
        position: "relative",
        width: 270,
        margin: "auto",
        zIndex: 1,
        "& img": {
          maxWidth: "100%",
          maxHeight: 450,
        },
      },
      mapPin: {
        position: "absolute",
        zIndex: 2,
        left: 170,
        top: 110,
        backgroundImage: `url("${mapPin}")`,
        backgroundRepeat: "no-repeat",
        width: 32,
        height: 32,
        cursor: "pointer",
        "&:hover": {
          zIndex: 3,
          backgroundImage: `url("${mapPinHover}")`,
        },
        "&:active": {
          zIndex: 3,
          backgroundImage: `url("${mapPinHover}")`,
        },
      },
      mapPinHoverStyle: {
        position: "absolute",
        left: 170,
        top: 110,
        backgroundRepeat: "no-repeat",
        width: 32,
        height: 32,
        cursor: "pointer",
        zIndex: 3,
        backgroundImage: `url("${mapPinHover}")`,
      },
      mapPinSelected: {
        zIndex: 2,
        backgroundImage: `url("${mapPinSelected}")`,
        "&:hover": {
          zIndex: 3,
          backgroundImage: `url("${mapPinSelected}")`,
        },
      },
      tooltip: {
        padding: 0,
        maxWidth: 300,
        maxHeight: 287,
        minWidth: 300,
        backgroundColor: theme.palette.common.white,
        boxShadow: theme.shadows[4],
      },
      tooltipArrow: {
        width: 22,
        height: 15,
        "&::before": {
          backgroundColor: theme.palette.background.default,
          boxShadow: theme.shadows[10],
        },
      },
      tooltipPlacementTop: {
        "& $tooltipArrow": {
          marginTop: "-15px !important",
          marginBottom: "-15px !important",
        },
      },
      tooltipPlacementBottom: {
        "& $tooltipArrow": {
          marginTop: "-15px !important",
          marginBottom: "-15px !important",
        },
      },
      viewAllLocation: {
        position: "absolute",
        top: theme.spacing(-1.2),
        right: theme.spacing(-9),
        minWidth: 198,
      },
      viewAllLocationDark: {
        right: -80,
      },
      viewAllButton: {
        padding: theme.spacing(1, 1.5),
        height: 45,
        backgroundColor: "transparent !important",
        color: `${theme.palette.text.primary} !important`,
        "&:before": {
          borderColor: `#111 !important`,
        },
      },
    };
  })();

export type Location = {
  id: number;
  left: number;
  top: number;
  name: string;
  infoText: string;
  imageUrl: string;
  country: string;
  selected?: boolean;
  isHover?: boolean;
  locationURL?: string;
};

type StaticMapCardProps = {
  mapUrl: string;
  mapPin: string;
  mapPinHover: string;
  mapPinSelected: string;
  imageLoader?: ImageLoader;
  locations: Location[];
  overrideProps?: LocationMapOverrideProps;
  viewLocationLabel?: string;
  compareLocationLabel?: string;
  compareLocationURL?: string;
  isDarkTheme?: boolean;
};

const StaticMapCard: React.FC<StaticMapCardProps> = ({
  mapUrl,
  imageLoader,
  locations,
  mapPin,
  mapPinHover,
  mapPinSelected,
  overrideProps,
  viewLocationLabel,
  compareLocationLabel,
  compareLocationURL,
  isDarkTheme,
}: PropsWithChildren<StaticMapCardProps>) => {
  const classes = useStyles(mapPin, mapPinHover, mapPinSelected);
  const [infoWindowOpen, setInfoWindowOpen] = useState({});

  const handleInfoWindowClose = React.useCallback(
    debounce((id) => {
      setInfoWindowOpen((prevInfoWindowOpen) => {
        const updatedInfoWindowOpen = { ...prevInfoWindowOpen };
        delete updatedInfoWindowOpen[id];
        return updatedInfoWindowOpen;
      });
    }, 100),
    [],
  );

  const handleInfoWindowToggle = React.useCallback(
    (id: number, event?: React.MouseEvent | React.TouchEvent) => {
      event?.preventDefault();
      event?.stopPropagation();

      setInfoWindowOpen((prev) => {
        const newState = { ...prev };
        // Close all other tooltips
        Object.keys(newState)?.forEach((key) => {
          if (Number(key) !== id) newState[key] = false; // Convert key to number
        });
        // Toggle the clicked one
        newState[id] = !prev[id];
        return newState;
      });
    },
    [],
  );

  return (
    <div
      className={classnames(classes.root, {
        [classes.isCompareLocations]: overrideProps?.showCompareLocations,
      })}
    >
      <div className={classes.mapCard}>
        <FHNextImage
          src={mapUrl}
          width={270}
          height={450}
          alt="Map"
          loader={imageLoader}
        />
        {locations.map((location, i) => (
          <ClickAwayListener
            onClickAway={() => {
              Object.keys(infoWindowOpen).forEach((id) => {
                handleInfoWindowClose(id);
              });
            }}
            key={`location-${i}`}
          >
            <div key={location.id}>
              <Tooltip
                open={infoWindowOpen[location.id] || false}
                onClose={() => handleInfoWindowClose(location.id)}
                title={
                  <MapCardPinPopup
                    id={location.id}
                    locationName={location.name}
                    locationMapInfo={location.infoText}
                    locationImage={location.imageUrl}
                    setInfoIndex={(index) => index}
                    isWidget={overrideProps?.isWidget}
                    country={location.country}
                    handleUpdateLocation={overrideProps?.handleUpdateLocation}
                    listOfCheckedLocationIds={
                      overrideProps?.listOfCheckedLocationIds
                    }
                    viewLocationLabel={viewLocationLabel}
                    locationURL={location?.locationURL}
                    showNewLocationTag={overrideProps?.showNewLocationTag}
                  />
                }
                disableFocusListener
                disableHoverListener
                disableTouchListener
                arrow
                placement="top"
                PopperProps={{
                  disablePortal: true,
                }}
                classes={{
                  tooltip: classes.tooltip,
                  arrow: classes.tooltipArrow,
                  tooltipPlacementBottom: classes.tooltipPlacementBottom,
                  tooltipPlacementTop: classes.tooltipPlacementTop,
                }}
              >
                <div
                  className={classNames(classes.mapPin, {
                    [classes.mapPinSelected]: overrideProps?.isWidget
                      ? location.selected
                      : infoWindowOpen[location.id],
                    [classes.mapPinHoverStyle]: location.isHover,
                  })}
                  onMouseEnter={() =>
                    overrideProps?.setHoverLocationId(location.id?.toString())
                  }
                  onMouseLeave={() => overrideProps?.setHoverLocationId("")}
                  onClick={(event) => {
                    event.stopPropagation();
                    handleInfoWindowToggle(location.id);
                  }}
                  onTouchStart={(e) => {
                    e.preventDefault();
                    overrideProps?.setHoverLocationId(location.id?.toString());
                    if (isIOS()) {
                      setTimeout(
                        () => handleInfoWindowToggle(location.id, e),
                        100,
                      );
                    }
                  }}
                  onTouchEnd={(e) => {
                    e.preventDefault();
                    if (!isIOS()) {
                      handleInfoWindowToggle(location.id, e);
                    }
                    overrideProps?.setHoverLocationId("");
                  }}
                  style={{ left: location.left, top: location.top }}
                />
              </Tooltip>
            </div>
          </ClickAwayListener>
        ))}
        {!overrideProps?.isWidget &&
          overrideProps?.showCompareLocations &&
          compareLocationURL &&
          compareLocationLabel && (
            <Hidden lgDown>
              <div
                className={classnames(classes.viewAllLocation, {
                  [classes.viewAllLocationDark]: isDarkTheme,
                })}
              >
                <FHButton
                  href={compareLocationURL}
                  fhStyle="secondary"
                  className={classnames({
                    [classes.viewAllButton]: isDarkTheme,
                  })}
                >
                  {compareLocationLabel}
                </FHButton>
              </div>
            </Hidden>
          )}
      </div>
    </div>
  );
};

export default React.memo(StaticMapCard);
